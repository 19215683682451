<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>加油信息</el-breadcrumb-item>
      <el-breadcrumb-item><div id="test" @click="laqu">用油分析列表</div></el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 内容开始 -->
    <el-card class="box-card" v-loading="loading">
      <!-- 使用单位 -->
      <div class="heard">
        <div class="top">
          <span>使用单位：</span>
          <el-select v-model="current_org" placeholder="请选择使用单位" clearable @clear="handleEmpty">
            <el-option v-for="item in trashtypeData" :key="item.id" :label="item.node_name" :value="item.id" @click.native="choicedrug"></el-option>
          </el-select>
        </div>
        <!-- 车辆类型 -->
        <!-- <div class="tops">
          <span>类型：</span>
          <el-select v-model="types" placeholder="请选择车辆类型">
            <el-option v-for="item in typelist" :key="item.id" :label="item.name" :value="item.id" @click.native="chetype"></el-option>
          </el-select>
        </div> -->

        <div>
          <div class="top">
            <span>车辆类型:</span>
            <el-cascader :options="auto_typelist" :show-all-levels="false" :props="Props" @change="typeChange" clearable class="lefts"></el-cascader>
          </div>
        </div>

        <!-- 搜索车牌号 -->
        <div class="tops">
          <span>搜索车牌号：</span>
          <el-autocomplete
            :trigger-on-focus="false"
            placeholder="请输入车牌号"
            v-model="key"
            :fetch-suggestions="salesperson"
            value-key="auto_number"
            @select="XiaohandleSelect($event, '车牌号')"
          ></el-autocomplete>
        </div>
        <div class="tops riqi">
          <span>日期：</span>
          <el-date-picker v-model="yue" type="month" @change="gettime" value-format="yyyy-MM" placeholder="选择月" :picker-options="pickerOptions"></el-date-picker>
        </div>
      </div>
      <div class="nav">
        <div class="tops">
          <span>筛选选择：</span>
          <el-select v-model="youli" placeholder="选择条件">
            <el-option v-for="item in youlilist" :key="item.id" :label="item.name" :value="item.id" @click.native="lichengs"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <span>排序：</span>
          <el-select v-model="paixu" placeholder="选择排序方式">
            <el-option v-for="item in paixulist" :key="item.id" :label="item.name" :value="item.id" @click.native="youhaos"></el-option>
          </el-select>
        </div>
        <el-button class="boder" @click="laqulist">本月超出油耗参考车辆:{{ auto_ring }}辆</el-button>
        <div class="boder">本月油料成本:{{ toFixed(total_price) }}元</div>
        <!-- <el-button class="boder rights" @click="zheng">正常车辆</el-button> -->
        <el-button class="boder rights" @click="tiao">加油记录</el-button>
      </div>

      <!-- 下边主体 -->
      <div v-if="falgs">
        <div class="box">
          <!-- 内容开始 -->
          <div class="list" v-for="(item, index) in lielist" :key="index">
            <ul>
              <div class="guishu bold">归属项目:{{ item.current_org_name }}</div>
              <li>历史里程:{{ toFixed(item.history_kilometre) }}KM</li>
              <li>车牌号码:{{ item.auto_number }}</li>
              <li>车辆类型:{{ item.type_name }}</li>
              <li>车辆品牌:{{ item.brand_name }}</li>
              <div class="xianxian"></div>
              <li>
                {{ mountedsone }}月里程:{{ toFixed(item.kilometre_sum) }}
                <span class="abc">KM</span>
              </li>
              <li>
                同比{{ month }}月:
                <i :class="item.kilometre_ratio >= 0 ? 'el-icon-top' : 'el-icon-bottom'"></i>
                {{ item.kilometre_ratio }}%
              </li>
              <li>
                平均油耗:{{ item.oil_consumption }}
                <span class="abc">L/100KM</span>
              </li>
              <li>
                同比{{ month }}月:
                <i :class="item.oil_consumption_ratio >= 0 ? 'el-icon-top' : 'el-icon-bottom'"></i>
                {{ item.oil_consumption_ratio }}%
              </li>
              <li>
                加油金额:{{ toFixed(item.total_price / 100) }}
                <span class="abc">元</span>
              </li>
              <li>
                同比{{ month }}月:
                <i :class="item.total_price_ratio >= 0 ? 'el-icon-top' : 'el-icon-bottom'"></i>
                {{ item.total_price_ratio }} %
              </li>
              <li>
                加油汇总:{{ item.volume_sum | youliang }}
                <span class="abc">L</span>
              </li>
              <li>
                同比{{ month }}月:
                <i :class="item.auto_volume_sum_ratio >= 0 ? 'el-icon-top' : 'el-icon-bottom'"></i>
                {{ item.auto_volume_sum_ratio }} %
              </li>
            </ul>
            <div class="tu_top">加油统计图表</div>
            <div :id="'myEchart_' + index" style="width: 450px; height: 300px"></div>
          </div>
        </div>
        <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
      </div>
      <div v-if="falg" class="wu">暂无数据</div>
    </el-card>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import Fenye from '../../../components/Fenye'
import { Getxiangmu, GetautoList, Getyonglist, GetUseTypeList } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          const FullYear = time.getFullYear()
          let myDate = new Date()
          // const Month = time.getMonth() + 1
          if (FullYear < 2021) {
            return true
          } else {
            let t = myDate.getDate()
            // 如果想包含本月本月 - 8.64e7 * t 就不需要了，
            // 如果想之前的不能选择把 > 换成 <
            return time.getTime() > Date.now() - 8.64e7 * t
          }
          // return false
        }
      },
      gettimes: '',
      mountedsone: '',
      month: '',
      dengauto_ids: '',
      loading: true,
      yue: '', // 月份选择器
      auto_id: '', // 车辆id
      total_price: '', // 本月油料成本
      auto_ring: '', // 超出参考车辆
      lielist: [], // 请求下来的数据
      current_org: '', // 项目下拉框选中的值
      trashtypeData: [], // 下拉框的数据
      types: '', // 车辆类型选择
      key: '', // 搜索车牌号
      youli: '', // 筛选里程或者油耗
      paixu: '', // 油耗排序
      auto_type: '',
      auto_typelist: [],
      falg: false,
      falgs: false,
      ffage: false,
      auto_ids: '', // 超出车辆id
      Props: {
        value: 'id',
        label: 'tname'
      },
      // 车辆类型
      typelist: [
        { name: '运营车辆', id: 1 },
        { name: '管理车辆', id: 2 }
      ],
      youlilist: [
        { name: '行驶里程', id: 1 },
        { name: '平均油耗', id: 2 }
      ],
      paixulist: [
        { name: '从少到多', id: 1 },
        { name: '从多到少', id: 2 }
      ],
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 9,
        // 共有多少条
        count: 0
      }
    }
  },
  mounted() {
    this.getCurrentTime()
    switch (this.gettimes) {
      case 1:
        this.mountedsone = 12
        break
      case 2:
        this.mountedsone = 1
        break
      case 3:
        this.mountedsone = 2
        break
      case 4:
        this.mountedsone = 3
        break
      case 5:
        this.mountedsone = 4
        break
      case 6:
        this.mountedsone = 5
        break
      case 7:
        this.mountedsone = 6
        break
      case 8:
        this.mountedsone = 7
        break
      case 9:
        this.mountedsone = 8
        break
      case 10:
        this.mountedsone = 9
        break
      case 11:
        this.mountedsone = 10
        break
      case 12:
        this.mountedsone = 11
        break
    }

    switch (this.mountedsone) {
      case 1:
        this.month = 12
        break
      case 2:
        this.month = 1
        break
      case 3:
        this.month = 2
        break
      case 4:
        this.month = 3
        break
      case 5:
        this.month = 4
        break
      case 6:
        this.month = 5
        break
      case 7:
        this.month = 6
        break
      case 8:
        this.month = 7
        break
      case 9:
        this.month = 8
        break
      case 10:
        this.month = 9
        break
      case 11:
        this.month = 10
        break
      case 12:
        this.month = 11
        break
    }
    // this.loadingInstance = Loading.service({
    //     // 动画中的文字
    //     text: '加载中',
    //     background: 'rgba(0, 0, 0, 0.7)',
    //   })
    setTimeout(() => {
      this.loading = false
    }, 4000)
    this.Getxiangmulist() // 拉取项目目录
    this.Getlistsss() // 拉取列表数据
    // this.draw_Bar()
    this.GetUseTypeLists()
  },
  filters: {
    youliang(val) {
      let newVal = parseFloat(val).toFixed(2)
      return newVal
    }
  },
  methods: {
    getCurrentTime() {
      //获取当前时间并打印
      let mm = new Date().getMonth() + 1
      this.gettimes = mm
      console.log(this.gettimes)
    },
    toFixed(val) {
      return parseFloat(val).toLocaleString('en', {
        // minimumFractionDigits: 2,
        // maximumFractionDigits: 2,
      })
    },

    handleEmpty() {
      this.current_org = ''
      this.Getlistsss()
    },
    // 车辆类型选中
    typeChange(e) {
      this.auto_type = e[e.length - 1]
      //  this.Getlistsss()
      if (this.auto_type !== undefined) {
        this.Getlistsss()
      } else {
        this.auto_type = ''
        this.Getlistsss()
      }
    },

    // 跳转到加油记录
    tiao() {
      this.$router.push({
        path: `comerecord`
      })
    },
    laqu() {
      this.Getlistsss()
    },
    laqulist() {
      this.ffage = !this.ffage
      if (this.ffage) {
        this.pageData.page = 1
        this.auto_ids = this.dengauto_ids
        this.Getlistsss()
      } else {
        this.pageData.page = 1
        this.dengauto_ids = ''
        this.auto_ids = ''
        this.Getlistsss()
      }
    },
    // zheng() {
    //   this.pageData.page = 1
    //   this.dengauto_ids = ''
    //   this.auto_ids = ''
    //   this.Getlistsss()
    // },
    // 选中项目的值
    choicedrug(e) {
      this.Getlistsss() // 拉取列表数据
    },
    // 选中车辆类型的值
    chetype(e) {
      this.Getlistsss() // 拉取列表数据
    },
    // 输入车辆匹配数据
    salesperson(queryString, cb) {
      this.getallfun(queryString, cb)
    },
    // 搜索车辆选中
    XiaohandleSelect(e, term) {
      this.auto_id = e.id
      console.log(this.auto_id)
      this.Getlistsss()
    },
    // 里程排序选中
    lichengs() {
      this.Getlistsss() // 拉取列表数据
    },
    // 平均油耗排序选中
    youhaos() {
      this.Getlistsss()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlistsss()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getlistsss()
    },
    // 时间
    gettime(yue) {
      console.log(yue)
      if (yue !== null) {
        let year = yue.split('-')
        let dates = year[1]
        console.log(dates, ';ssss')
        this.mountedsone = dates
        switch (dates) {
          case '01':
            this.month = '12'
            break
          case '02':
            this.month = '01'
            break
          case '03':
            this.month = '02'
            break
          case '04':
            this.month = '03'
            break
          case '05':
            this.month = '04'
            break
          case '06':
            this.month = '05'
            break
          case '07':
            this.month = '06'
            break
          case '08':
            this.month = '07'
            break
          case '09':
            this.month = '08'
            break
          case '10':
            this.month = '09'
            break
          case '11':
            this.month = '10'
            break
          case '12':
            this.month = '11'
            break
        }
      }else{
          switch (this.gettimes) {
      case 1:
        this.mountedsone = 12
        break
      case 2:
        this.mountedsone = 1
        break
      case 3:
        this.mountedsone = 2
        break
      case 4:
        this.mountedsone = 3
        break
      case 5:
        this.mountedsone = 4
        break
      case 6:
        this.mountedsone = 5
        break
      case 7:
        this.mountedsone = 6
        break
      case 8:
        this.mountedsone = 7
        break
      case 9:
        this.mountedsone = 8
        break
      case 10:
        this.mountedsone = 9
        break
      case 11:
        this.mountedsone = 10
        break
      case 12:
        this.mountedsone = 11
        break
    }

    switch (this.mountedsone) {
      case 1:
        this.month = 12
        break
      case 2:
        this.month = 1
        break
      case 3:
        this.month = 2
        break
      case 4:
        this.month = 3
        break
      case 5:
        this.month = 4
        break
      case 6:
        this.month = 5
        break
      case 7:
        this.month = 6
        break
      case 8:
        this.month = 7
        break
      case 9:
        this.month = 8
        break
      case 10:
        this.month = 9
        break
      case 11:
        this.month = 10
        break
      case 12:
        this.month = 11
        break
    }
      }
      this.Getlistsss()
    },

    draw_Bar() {
      //此处for循环多次初始化 echarts 实例
      for (let i = 0; i < this.lielist.length; i++) {
        var myChart = this.$echarts.init(document.getElementById('myEchart_' + i)) // 可以换一种方式 比如动态的拼接一个id 因为上面和下面都是循环 所有可以通过索引拼一个ID
        var option = {
          tooltip: {},
          legend: {
            data: ['行驶里程KM', '平均油耗(L/100KM)', '标准油耗(L/100KM)']
          },
          xAxis: {
            data: this.lielist[i].show_data.time
          },
          yAxis: [
            {
              type: 'value',
              name: '单位KM'
            },
            {
              type: 'value',
              name: '单位L/100KM'
            }
          ],
          tooltip: {
            trigger: 'axis', // 触发类型坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
            axisPointer: {
              // // 坐标轴指示器配置项
              type: 'shadow' // 'shadow' 阴影指示器
            }
          },
          series: [
            {
              name: '行驶里程KM',
              type: 'bar',
              data: this.lielist[i].show_data.month_kilometre,
              label: {
                show: false,
                position: 'top',
                distance: 5,
                verticalAlign: 'middle',
                formatter: e => {
                  console.log(e)
                  return e[0].value + 'KM'
                }
              }
            },
            {
              name: '平均油耗(L/100KM)',
              type: 'line',
              symbol: 'circle',
              yAxisIndex: 1,
              data: this.lielist[i].show_data.month_oil_consumption
            },
            {
              name: '标准油耗(L/100KM)',
              type: 'line',
              symbol: 'circle',
              yAxisIndex: 1,
              data: this.lielist[i].show_data.standard_oil_consumption
            }
          ]
        }
        //使用刚指定的配置项和数据显示图表
        myChart.setOption(option)
      }
    },

    // 请求区
    //--------------------------------------------//

    // 获取项目
    async Getxiangmulist() {
      const { data } = await Getxiangmu()
      this.trashtypeData = data.data
      //   console.log(this.trashtypeData)
    },

    // 获取车辆
    async getallfun(key, cd) {
      const { data } = await GetautoList({ key: key, page: 1, size: 100 })
      this.userlist = data.data.list
      cd(data.data.list)
    },
    async Getchao() {
      const { data } = await Getyonglist({ auto_ids: this.auto_ids })
      this.lielist = data.data.list
      this.total_price = data.data.total_price / 100
      this.auto_ring = data.data.auto_ring
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.$nextTick(() => {
        this.draw_Bar()
      })
    },

    async GetUseTypeLists() {
      const { data } = await GetUseTypeList()
      this.auto_typelist = data.data.list
    },

    // 获取用油分析列表
    async Getlistsss() {
      const { data } = await Getyonglist({
        ...this.pageData,
        month_time: this.yue,
        current_org: this.current_org,
        type: this.types,
        search_key: this.youli,
        search_sort: this.paixu,
        auto_id: this.auto_id,
        auto_ids: this.auto_ids,
        auto_type: this.auto_type
      })

      this.lielist = data.data.list
      this.total_price = data.data.total_price / 100
      this.auto_ring = data.data.auto_ring
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.dengauto_ids = data.data.auto_ids
      if (data.data.count == 0) {
        this.falg = true
      } else {
        this.falgs = true
      }
      this.$nextTick(() => {
        // 这里要加上 这个  因为界面是循环lielist 界面循环的时候有一个时间 如果直接this.draw_Bar 会导致dom 节点还没出来 这样会导致拿不到上面拼接的那个ID 如果拿不到就渲染不上
        // this.$nextTick  大概意思是 界面循环完成之后 在去执行他的回调方法 这样保证ID 一定会拿到
        this.draw_Bar()
      })
    }
  }
}
</script>

<style  scoped>
.heard {
  width: 1450px;
  display: flex;
  justify-content: space-between;
}
.nav {
  width: 1450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.boder {
  height: 40px;
  line-height: 40px;
  border: 1px solid #c0c4cc;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 14px;
}
.box {
  margin-top: 30px;
  width: 100%;
  /* border: 1px solid #000; */
  display: flex;
  flex-wrap: wrap;
  max-width: 1450px !important;
  justify-content: space-around;
}
.list {
  width: 460px;
  border: 1px solid #999;
  margin-top: 15px;
  box-sizing: border-box;
  padding: 0px 0px 0px 10px;
  border-radius: 10px;
}
ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 0px;
  text-align: center;
  margin-left: 40px;
}
li {
  width: 198px;
  height: 30px;
  line-height: 30px;
  text-align: left;
}
.tu_top {
  font-size: 14px;
  text-align: center;
}
.rights {
  margin-right: 15px;
}
.wu {
  margin-top: 50px;
  text-align: center;
  font-size: 14px;
  color: #999;
  border-bottom: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
}
/* .riqi { */
/* display: none; */
/* } */
#test:hover {
  cursor: pointer;
  color: #409eff;
  font-weight: bold;
}
.abc {
  font-size: 12px;
}
.guishu {
  width: 100%;
  /* border: 1px solid #000; */
  text-align: left;
  height: 30px;
  line-height: 30px;
}
.xianxian {
  margin: 5px 0px;
  width: 80%;
  border: 1px dashed #999;
}

.bold {
  font-weight: bold;
}
.lefts {
  margin-left: 10px;
}
</style>